const AlertSounds = {
    SOUND_1: {
        label: 'Som de Alerta 1',
        value: 'https://storage.googleapis.com/simulador-fgts/audio/chat-sound-1.mp3',
    },
    SOUND_2: {
        label: 'Som de Alerta 2',
        value: 'https://storage.googleapis.com/simulador-fgts/audio/ambient-flute-notification-1-185273.mp3',
    },
    SOUND_3: {
        label: 'Som de Alerta 3',
        value: 'https://storage.googleapis.com/simulador-fgts/audio/error-notification-banjo-45430.mp3',
    },
    SOUND_4: {
        label: 'Som de Alerta 4',
        value: 'https://storage.googleapis.com/simulador-fgts/audio/ding-126626.mp3',
    },
    SOUND_5: {
        label: 'Som de Alerta 5',
        value: 'https://storage.googleapis.com/simulador-fgts/audio/greanpatch-166007.mp3',
    },
    SOUND_6: {
        label: 'Som de Alerta 6',
        value: 'https://storage.googleapis.com/simulador-fgts/audio/level-up-191997.mp3',
    },
    SOUND_7: {
        label: 'Som de Alerta 7',
        value: 'https://storage.googleapis.com/simulador-fgts/audio/message-notification-103496.mp3',
    },
    SOUND_8: {
        label: 'Som de Alerta 8',
        value: 'https://storage.googleapis.com/simulador-fgts/audio/message-notification-190034.mp3',
    },
    SOUND_9: {
        label: 'Som de Alerta 9',
        value: 'https://storage.googleapis.com/simulador-fgts/audio/new-notification-138807.mp3',
    },
    SOUND_10: {
        label: 'Som de Alerta 10',
        value: 'https://storage.googleapis.com/simulador-fgts/audio/notification-interface-success-positive-corrects-132471.mp3',
    },
    SOUND_11: {
        label: 'Som de Alerta 11',
        value: 'https://storage.googleapis.com/simulador-fgts/audio/notification-positive-230572.mp3',
    },
    SOUND_12: {
        label: 'Som de Alerta 12',
        value: 'https://storage.googleapis.com/simulador-fgts/audio/notification-sound-2-253324.mp3',
    },
    SOUND_13: {
        label: 'Som de Alerta 13',
        value: 'https://storage.googleapis.com/simulador-fgts/audio/notification-sound-ringtone-for-phone-163638.mp3',
    },
    SOUND_14: {
        label: 'Som de Alerta 14',
        value: 'https://storage.googleapis.com/simulador-fgts/audio/pleasant-violin-notification-8-186542.mp3',
    },
    SOUND_15: {
        label: 'Som de Alerta 15',
        value: 'https://storage.googleapis.com/simulador-fgts/audio/point-smooth-beep-230573.mp3',
    },
    SOUND_16: {
        label: 'Som de Alerta 16',
        value: 'https://storage.googleapis.com/simulador-fgts/audio/pop-sound-effect-226108.mp3',
    },
    SOUND_17: {
        label: 'Som de Alerta 17',
        value: 'https://storage.googleapis.com/simulador-fgts/audio/reverby-notification-sound-246407.mp3',
    },
    SOUND_18: {
        label: 'Som de Alerta 18',
        value: 'https://storage.googleapis.com/simulador-fgts/audio/simple-notification-152054.mp3',
    },
    SOUND_19: {
        label: 'Som de Alerta 19',
        value: 'https://storage.googleapis.com/simulador-fgts/audio/the-notification-email-143029.mp3',
    },
    SOUND_20: {
        label: 'Som de Alerta 20',
        value: 'https://storage.googleapis.com/simulador-fgts/audio/ui-1-sound-effect-notification-242232.mp3',
    },
    SOUND_21: {
        label: 'Som de Alerta 21',
        value: 'https://storage.googleapis.com/simulador-fgts/audio/ui-2-sound-effect-notification-warn-test-242231.mp3',
    },
    SOUND_22: {
        label: 'Som de Alerta 22',
        value: 'https://storage.googleapis.com/simulador-fgts/audio/wrong-answer-129254.mp3',
    },
};

export default AlertSounds;