import { useEffect, useRef, useState } from "react";
import {
  Col,
  Row,
  Card,
  CardBody,
  CardHeader,
  InputGroup,
  Label,
  FormGroup,
  InputGroupAddon,
  Button,
  Alert,
} from "reactstrap";
import Swal from "sweetalert2";
import { agendSendMessage, getAllInstance } from "../../services/Instance";

import { getAllGroups, getGroupNumbers } from "../../services/Client-Schedule";

import ReactTextareaAutocomplete from "@webscopeio/react-textarea-autocomplete";
import emoji from "@jukben/emoji-search";
import "@webscopeio/react-textarea-autocomplete/style.css";

import IconBateria from "../chatbot/components/assets/bateria.png";
import IconConect from "../chatbot/components/assets/conexao.png";
import User from "../chatbot/components/assets/user.png";
import Send from "../chatbot/components/assets/send.png";

import {
  PreviewContainer,
  PhoneHeader,
  PreviewHeader,
  PreviewFooter,
  PreviewWrap,
  Left,
  Center,
  Icons,
  ImgBateria,
  ImgContect,
  UserAvatar,
  ImgAvatar,
  PreviewBody,
  Paragraph,
  MessagePhone,
  ImagePreview,
  CardPhone,
} from "./components/CardPhoneStyle.js";
import { getTemplate } from "../../services/Campaign-Template/index.js";
import { size } from "polished";

const Messages = () => {
  const ref = useRef(600);
  const [btnBlocked, setBtnBlocked] = useState(true);
  const [numbersPhone, setNumbersPhone] = useState("");
  const [instances, setInstances] = useState(null);
  const [listTemplates, setListTemplates] = useState([]);
  const [message, setMessage] = useState({
    title: null,
    numbers: [],
    message: null,
    instance: null,
    img: null,
    groups: null,
    csv: null,
  });
  const [showAlert, setShowAlert] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState("");
  const [selectedTemplate, setSelectedTemplate] = useState("");
  const [groupList, setGroupList] = useState([]);
  const [selectionMode, setSelectionMode] = useState("");
  const [selectedGroupId, setSelectedGroupId] = useState();
  const [imagePreview, setImagePreview] = useState(null);
  const [showGroupNumbers, setShowGroupNumbers] = useState(false);
  const [numberList, setNumberList] = useState([]);

  const isFormValid = () => {
    const isTitleValid = message.title !== null;
    const isGroupsValid =
      selectionMode === "escolherGrupos" && selectedGroup !== null;
    const isNumbersValid =
      selectionMode === "digitarNumeros" && message.numbers.length > 0;
    const isImportValid = selectionMode === "importacao" && message.csv;
    const isMessageValid = message.message && message.message.trim() !== "";
    const isInstanceSelected = message.instance !== null;

    return (
      !(isGroupsValid || isNumbersValid || isImportValid) ||
      !isMessageValid ||
      !isInstanceSelected ||
      !isTitleValid
    );
  };

  const handleGroupSelect = async (groupId) => {
    Swal.fire({
      title: "Carregando, Por favor aguarde...",
      text: "Por favor aguarde.",
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    try {
      const response = await getGroupNumbers(groupId);
      Swal.close();
      Array.isArray(response.data.resultSet);
      setNumberList([...response.data.resultSet]);
      setShowGroupNumbers(true);
    } catch (error) {
      Swal.close();
      console.error("Erro ao buscar números do grupo", error);
    }
  };

  const [selectedTemplateContent, setSelectedTemplateContent] = useState("");

  const getBase64 = (file) => {
    return new Promise((resolve) => {
      let fileInfo;
      let baseURL = "";
      // Make new FileReader
      let reader = new FileReader();
      // Convert the file to base64 text
      reader.readAsDataURL(file);

      // on reader load somthing...
      reader.onload = () => {
        // Make a fileInfo Object
        baseURL = reader.result;
        resolve(baseURL);
      };
    });
  };

  const csvImport = (file) => {
    return new Promise((resolve) => {
      let fileInfo;
      let baseURL = "";
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        baseURL = reader.result;
        resolve(baseURL);
      };
    });
  };

  const getGroup = async () => {
    Swal.fire({
      title: "Carregando, Por favor aguarde...",
      text: "Por favor aguarde.",
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    try {
      const response = await getAllGroups().then((res) => {
        Swal.close();
        return res;
      });
      Array.isArray(response.data.resultSet);
      setGroupList([...response.data.resultSet]);
    } catch (error) {
      Swal.close();
      console.error("Erro ao buscar a lista de grupos", error);
    }
  };

  const handleAddGroup = async () => {
    if (selectedGroup && selectedGroup.id !== 0) {
      setMessage({
        ...message,
        groups: selectedGroup.id,
      });
    }
  };

  const handleModeSwitchResponse = async (switchMode) => {
    if (switchMode) {
      if (selectionMode === "escolherGrupos") {
        setSelectionMode("digitarNumeros");
        setSelectedGroup("");
        setNumberList([]);
        setBtnBlocked(true);
      } else if (selectionMode === "digitarNumeros") {
        setNumbersPhone("");
        setMessage({
          ...message,
          numbers: [],
          instance: null,
        });
        setSelectionMode("escolherGrupos");
      }
    }
    Swal.close();
  };

  const handleShowModeSwitchModal = () => {
    Swal.fire({
      icon: "question",
      title: "AVISO",
      text:
        selectionMode === "escolherGrupos"
          ? 'Você já selecionou grupos. Se mudar para "Digitar Números", os grupos serão removidos. Deseja continuar?'
          : 'Você já escolheu números de telefone. Se mudar para "Escolher Grupos", os números serão removidos. Deseja continuar?',
      showCancelButton: true,
      confirmButtonText: "Sim",
      cancelButtonText: "Não",
    }).then((result) => {
      if (result.isConfirmed) {
        handleModeSwitchResponse(true);
      } else {
        handleModeSwitchResponse(false);
      }
    });
  };

  const handleSelectionModeChange = (event) => {
    const selectedMode = event.target.value;

    if (
      selectionMode === "escolherGrupos" &&
      selectedGroup &&
      selectedGroup.id !== 0 &&
      selectedGroupId === undefined
    ) {
      handleShowModeSwitchModal();
    } else if (
      selectionMode === "digitarNumeros" &&
      message.numbers.length > 0
    ) {
      handleShowModeSwitchModal();
    } else {
      setSelectionMode(selectedMode);
    }
  };

  const sizeImportBlock = () => {
    const upload = document.getElementById("upload");
    
    upload.addEventListener("change", function (e) {
      if (upload.files.length > 0) {
        const file = upload.files[0];
        const size = file.size;
        const fileName = file.name;
        if (!fileName.endsWith(".csv")) {
          Swal.fire({
            icon: "warning",
            title: "Aviso",
            text: "O arquivo deve estar no formato .csv!",
          });
          upload.value = "";
          return;
        }
        if (size > 1048576) {
          Swal.fire({
            icon: "warning",
            title: "Aviso",
            text: "O tamanho do arquivo não pode ser maior que 1MB!",
          });
          upload.value = "";
        }
      }
      e.preventDefault();
    });
  };  

  const handleChange = async (ev) => {
    if (ev.target.name === "title") {
      setMessage({
        ...message,
        title: ev.target.value,
      });
    }

    if (ev.target.name === "number") {
      setNumbersPhone(ev.target.value);
    }

    if (ev.target.name === "message") {
      setMessage({
        ...message,
        message: ev.target.value,
      });
      updateMessagePreview(ev);
    }

    if (ev.target.name === "instance") {
      setMessage({
        ...message,
        instance: ev.target.value,
      });
    }

    if (ev.target.name === "img") {
      setImagePreview("");
      let file = ev.target.files[0];
      if (file) {
        getBase64(file).then((e) => {
          setMessage({
            ...message,
            img: e,
          });
          setImagePreview(e);
        });
      }
    }

    if (ev.target.name === "csv") {
      let file = ev.target.files[0];
      if (file) {
        csvImport(file).then((e) => {
          setMessage({
            ...message,
            csv: e,
          });
        });
      }
    }
  };

  const handleAddPhone = async () => {
    const numbers = message.numbers;
    numbersPhone.split("\n").map((line) =>
      line.split("\t").map((value) => {
        numbers.push(value);
      })
    );
    setMessage({
      ...message,
      numbers: numbers,
    });

    setNumbersPhone("");
  };

  const handleSubmit = async () => {
    if (isFormValid()) {
      Swal.fire({
        icon: "warning",
        title: "Aviso",
        text: "Por favor preencha todos os campos, são obrigatórios!",
      });
      return;
    }

    Swal.fire({
      title: "Carregando, Por favor aguarde...",
      text: "Por favor aguarde",
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    if (selectionMode === "digitarNumeros") {
      message.numbers = message?.numbers ?? [];
    } else if (selectionMode === "escolherGrupos") {
      message.groups = selectedGroup.id;
    }

    await agendSendMessage(message)
      .then(() => {
        Swal.close();

        setShowAlert(false);

        Swal.fire({
          icon: "success",
          title: "Sucesso",
          text: "Mensagens estão sendo enviadas",
        });

        setMessage({
          title: null,
          numbers: [],
          message: "",
          groups: null,
          instance: null,
          csv: null,
        });

        setNumbersPhone("");
        setSelectedGroup("");
        setSelectionMode("");
        setNumberList([]);
        setSelectedTemplate("");
      })
      .catch(() => {
        Swal.fire({
          icon: "error",
          title: "Erro",
          text: "Não foi possível finalizar o registro",
        });
      });
  };

  const getAll = async () => {
    await getAllInstance().then((res) => {
      if (!res.data.status) return;
      setInstances(res.data.resultSet);
    });
  };

  const handleListTemplates = async () => {
    Swal.fire({
      title: "Carregando, Por favor aguarde...",
      text: "Por favor aguarde",
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    await getTemplate()
      .then((response) => {
        Swal.close();
        const templates = response.data.resultSet;
        setListTemplates(templates);
      })
      .catch((error) => {
        console.error("Error fetching templates:", error);
      });
  };

  const updateMessagePreview = (event) => {
    const preview = document.getElementById("message-preview");
    if (preview) {
      const messageText = event.target.value.replace(/\n/g, "<br>");
      preview.querySelector("p").innerHTML = messageText || "";
    }
  };

  const handleRemovePhone = (index) => {
    const updatedNumbers = [...message.numbers];
    updatedNumbers.splice(index, 1);
    setMessage((prevMessage) => ({
      ...prevMessage,
      numbers: updatedNumbers,
    }));
  };

  const handleRemoveAllPhones = () => {
    setMessage({ ...message, numbers: [] });
  };

  const handleTemplateSelection = (e) => {
    const selectedTemplateId = e.target.value;
    const selectedTemplate = listTemplates.find(
      (template) => template.id === selectedTemplateId
    );

    setSelectedTemplate(
      selectedTemplate
        ? { id: parseInt(selectedTemplateId), title: selectedTemplate.title }
        : null
    );
    setSelectedTemplateContent(
      selectedTemplate ? selectedTemplate.message : ""
    );

    setMessage({
      ...message,
      title: selectedTemplate ? selectedTemplate.title : "",
      message: selectedTemplate ? selectedTemplate.message : "",
    });
    updateMessagePreview({
      target: { value: selectedTemplate ? selectedTemplate.message : "" },
    });
  };

  const Item = ({ entity: { name, char } }) => <div>{`${name}: ${char}`}</div>;

  useEffect(() => {
    console.log("width", ref.current.offsetWidth);
    getAll();
  }, []);

  useEffect(() => {
    getGroup();
    handleListTemplates();
  }, []);

  useEffect(() => {
    setShowAlert(
      showGroupNumbers &&
        numberList.length === 0 &&
        selectedGroup !== null &&
        selectedGroup.id !== 0
    );
  }, [showGroupNumbers, numberList, selectedGroup]);

  useEffect(() => {
    const isTitleValid = message.title !== null;
    const isGroupsValid =
      selectionMode === "escolherGrupos" && selectedGroup !== null;
    const isNumbersValid =
      selectionMode === "digitarNumeros" && message.numbers.length > 0;
    const isImportValid = selectionMode === "importacao" && message.csv;
    const isMessageValid = message.message && message.message.trim() !== "";
    const isInstanceSelected = message.instance !== null;

    setBtnBlocked(
      !(isGroupsValid || isNumbersValid || isImportValid) ||
        !isMessageValid ||
        !isInstanceSelected ||
        !isTitleValid
    );
  }, [
    message.title,
    message.numbers,
    message.message,
    message.instance,
    message.csv,
    selectedGroup,
  ]);

  return (
    <div>
      <Row>
        <Col
          className="pr-grid-col d-flex flex-sm-column flex-column flex-lg-row justify-content-lg-start justify-content-center align-items-center"
          xs={12}
          lg={12}
        >
          <Card className="border-0 col-xl-8 col-lg-7 d-flex">
            <CardHeader className="border-0 bg-white">
              <div className="headline-2">GERAR LOTE DE CAMPANHA</div>
            </CardHeader>
            <CardBody>
              <FormGroup>
                <Label>Titulo</Label>
                <InputGroup>
                  <input
                    name="title"
                    className="form-control"
                    onChange={handleChange}
                    value={message.title || ""}
                  />
                </InputGroup>
              </FormGroup>
              <FormGroup>
                <Label>Imagem</Label>
                <InputGroup>
                  <input
                    name="img"
                    onBlur={handleChange}
                    className="form-control"
                    type="file"
                    accept="image/png, image/gif, image/jpeg"
                  />
                </InputGroup>
              </FormGroup>
              <div ref={ref}>
                <FormGroup>
                  <Label>Telefones</Label>
                  <InputGroup>
                    <div className="d-flex flex-lg-row flex-column">
                      <div>
                        <input
                          type="radio"
                          name="selectionMode"
                          id="digitarNumeros"
                          value="digitarNumeros"
                          checked={selectionMode === "digitarNumeros"}
                          onChange={handleSelectionModeChange}
                        />
                        <Label
                          className="form-check-label ml-2"
                          for="digitarNumeros"
                        >
                          Digitar Números
                        </Label>
                      </div>
                      <div>
                        <input
                          type="radio"
                          name="selectionMode"
                          id="escolherGrupos"
                          value="escolherGrupos"
                          className="ml-lg-5"
                          checked={selectionMode === "escolherGrupos"}
                          onChange={handleSelectionModeChange}
                        />
                        <Label
                          className="form-check-label ml-2"
                          for="escolherGrupos"
                        >
                          Escolher Grupo
                        </Label>
                      </div>
                      <div>
                        <input
                          type="radio"
                          name="selectionMode"
                          id="importacao"
                          value="importacao"
                          className="ml-lg-5"
                          checked={selectionMode === "importacao"}
                          onChange={handleSelectionModeChange}
                        />
                        <Label
                          className="form-check-label ml-2"
                          for="importacao"
                        >
                          Importaçao
                        </Label>
                      </div>
                    </div>
                  </InputGroup>
                </FormGroup>
                {selectionMode === "digitarNumeros" && (
                  <FormGroup>
                    <Label>Números de WhatsApp</Label>
                    <InputGroup>
                      <textarea
                        name="number"
                        value={numbersPhone}
                        className="form-control"
                        onChange={handleChange}
                        onBlur={handleChange}
                      ></textarea>
                      <InputGroupAddon addonType="append">
                        <Button color="secondary" onClick={handleAddPhone}>
                          ADICIONAR NÚMEROS
                        </Button>
                      </InputGroupAddon>
                    </InputGroup>

                    <div>
                      {message.numbers.length > 0 ? (
                        <>
                          <MessagePhone className="mt-2 rounded-left w-100 p-0">
                            <div className="m-0 alert alert-secondary rounded-0">
                              <div className="d-flex flex-lg-row flex-column mb-4">
                                <h4 className="text-dark m-0 mr-2 mb-lg-0 mb-2">
                                  Números adicionados
                                </h4>
                                <div
                                  className="badge badge-success d-flex align-items-center"
                                  style={{ maxWidth: "120px" }}
                                >
                                  Quantidade: {message.numbers.length}
                                </div>
                              </div>
                              <div
                                className="d-flex flex-wrap h-auto"
                                style={{ gap: "10px" }}
                              >
                                {message.numbers.map((number, index) => (
                                  <div className="d-flex align-itens-center">
                                    <span
                                      key={index}
                                      className="badge badge-success rounded-0"
                                    >
                                      {number}
                                    </span>
                                    <button
                                      className="btn-danger border-0"
                                      style={{
                                        padding: "2px 5px",
                                        fontSize: "12px",
                                        textAlign: "center",
                                        display: "flex",
                                        justifyContent: "center",
                                      }}
                                      onClick={() => handleRemovePhone(index)}
                                    >
                                      X
                                    </button>
                                  </div>
                                ))}
                              </div>
                            </div>
                          </MessagePhone>
                          <button
                            className="btn btn-danger mt-2"
                            onClick={handleRemoveAllPhones}
                          >
                            Remover Todos
                          </button>
                        </>
                      ) : (
                        <></>
                      )}
                    </div>
                  </FormGroup>
                )}

                {selectionMode === "escolherGrupos" && (
                  <FormGroup>
                    <Label>Escolha um grupo:</Label>
                    <InputGroup>
                      <select
                        className="form-control"
                        name="clientGroup"
                        value={selectedGroup ? selectedGroup.id : ""}
                        onChange={(e) => {
                          const selectedGroupId = e.target.value;
                          const selectedGroup = groupList.find(
                            (group) => group.id === selectedGroupId
                          );
                          setSelectedGroup(
                            selectedGroup
                              ? {
                                  id: parseInt(selectedGroupId),
                                  name: selectedGroup.name,
                                }
                              : null
                          );
                          handleGroupSelect(selectedGroupId);

                          handleAddGroup();
                        }}
                      >
                        <option value="">Selecione...</option>
                        {groupList.map((group) => (
                          <option key={group.id} value={group.id}>
                            {group.name}
                          </option>
                        ))}
                      </select>
                    </InputGroup>
                  </FormGroup>
                )}

                {selectionMode === "importacao" && (
                  <FormGroup>
                    <Label>Importação arquivo CSV</Label>
                    <InputGroup>
                      <input
                        id="upload"
                        name="csv"
                        type="file"
                        className="form-control"
                        onChange={handleChange && sizeImportBlock}
                        onBlur={handleChange}
                        accept=".csv"
                      />
                    </InputGroup>
                    <Alert
                      color="secondary"
                      className="text-primary"
                      style={{ fontSize: "12px" }}
                    >
                      <p>
                        Envie um arquivo em formato <strong>CSV</strong>, com
                        tamanho máximo de <strong> 1MB</strong>, contendo as
                        seguintes instruções:
                        <p>
                          A primeira linha deve conter as tags nas colunas
                          <strong>
                            {" "}
                            TELEFONE, NOME, VALOR1, VALOR2, VALOR3, VALOR4 e
                            VALOR5.
                          </strong>
                        </p>
                        Nas linhas seguintes, preencha o conteúdo desejado,
                        respeitando a estrutura das colunas definidas.
                      </p>
                    </Alert>
                  </FormGroup>
                )}

                {showGroupNumbers ? (
                  numberList.length > 0 ? (
                    <div className="mt-2 alert alert-secondary">
                      <div className="d-flex flex-lg-row flex-column mb-4">
                        <h4 className="text-dark m-0 mr-2 mb-lg-0 mb-2">
                          Números do Grupo
                        </h4>
                        <div
                          className="badge badge-success d-flex align-items-center"
                          style={{ maxWidth: "120px" }}
                        >
                          Quantidade: {numberList.length}
                        </div>
                      </div>
                      <div
                        className="d-flex flex-wrap"
                        style={{
                          maxHeight: "300px",
                          overflow: "auto",
                          gap: "10px",
                          maxWidth: "70vw",
                        }}
                      >
                        {numberList.map((number, index) => (
                          <span
                            key={index}
                            className="badge badge-success mb-1"
                          >
                            {number}
                          </span>
                        ))}
                      </div>
                    </div>
                  ) : (
                    showAlert &&
                    selectedGroup && (
                      <Alert className="alert alert-warning mb-3 text-warning">
                        Nenhum telefone cadastrado neste grupo.
                      </Alert>
                    )
                  )
                ) : null}

                <FormGroup>
                  <Label>Mensagem</Label>
                  <InputGroup>
                    <select
                      className="form-control mb-4"
                      name="clientGroup"
                      value={selectedTemplate ? selectedTemplate.id : ""}
                      onChange={(e) => handleTemplateSelection(e)}
                    >
                      <option value="">Modelos de campanha</option>
                      {listTemplates.map((template) => (
                        <option key={template.id} value={template.id}>
                          {template.title}
                        </option>
                      ))}
                    </select>
                  </InputGroup>
                  <InputGroup>
                    {/* <textarea
                    name="message"
                    value={message.message}
                    onChange={handleChange}
                    onBlur={handleChange}
                    className="form-control"
                    style={{ height: 120 }}
                  ></textarea> */}
                    <ReactTextareaAutocomplete
                      className="form-control w-100"
                      name="message"
                      style={{ height: 120, zIndex: 999 }}
                      containerStyle={{
                        width: ref.current.offsetWidth,
                        zIndex: 999,
                      }}
                      onChange={handleChange}
                      value={message.message}
                      onInput={updateMessagePreview}
                      loadingComponent={() => <span>Loading</span>}
                      trigger={{
                        ":": {
                          dataProvider: (token) => {
                            return emoji(token)
                              .slice(0, 20)
                              .map(({ name, char }) => ({ name, char }));
                          },
                          component: Item,
                          output: (item, trigger) => item.char,
                        },
                      }}
                    />
                    {selectionMode === "importacao" && (
                      <Alert
                        color="secondary"
                        className="text-primary"
                        style={{ fontSize: "12px" }}
                      >
                        <p>
                          Pode criar uma frase personalizada utilizando as
                          seguintes tags:
                          <strong>
                            [NOME], [VALOR1], [VALOR2], [VALOR3], [VALOR4] e
                            [VALOR5].
                          </strong>
                          <br />
                          <strong className="text-success">
                            Exemplo: Olá, [NOME], como está? Você ganhou um
                            desconto de [VALOR1]!
                          </strong>
                        </p>
                      </Alert>
                    )}
                    {selectionMode === "digitarNumeros" && (
                      <Alert
                        color="secondary"
                        className="text-primary"
                        style={{ fontSize: "12px" }}
                      >
                        <p>
                          Você pode personalizar as mensagens via WhatsApp.
                          Basta informar no campo{" "}
                          <strong>Números de WhatsApp</strong>, o número de
                          telefone, seguido de <strong>;</strong> e o nome do
                          cliente. <strong>Ex: 44999665533;JOÃO</strong>. <br />
                          Em seguida, no campo <strong>mensagem</strong>,
                          informe a tag <strong>[Nome]</strong> e,
                          automaticamente, aparecerá o nome do cliente
                          informado. Conforme exemplo abaixo:
                          <br />
                          <strong className="text-success">
                            Olá [NOME] como está?
                          </strong>
                        </p>
                      </Alert>
                    )}
                  </InputGroup>
                </FormGroup>
              </div>
              <FormGroup>
                <Label>Instância</Label>
                <select
                  name="instance"
                  className="form-control"
                  onChange={handleChange}
                  onBlur={handleChange}
                  value={message.instance || ""}
                >
                  <option value="">Selecione...</option>
                  {instances
                    ? instances.map((instance, key) =>
                        instance.isRead ? (
                          <option key={key} value={instance.name}>
                            {instance.name} -{" "}
                            {instance.isRead ? "ATIVO" : "INATIVO"}
                          </option>
                        ) : null
                      )
                    : null}
                </select>
              </FormGroup>
              <Button
                className="btn btn-lg btn-success"
                onClick={handleSubmit}
                disabled={btnBlocked}
              >
                AGENDAR LOTE
              </Button>
            </CardBody>
          </Card>
          <CardPhone>
            <PreviewContainer>
              <PhoneHeader>
                <Left />
                <Center />
                <Icons>
                  <ImgBateria src={IconBateria} alt="Bateria" />
                  <ImgContect src={IconConect} alt="Conexão" />
                </Icons>
              </PhoneHeader>
              <PreviewWrap>
                <PreviewHeader>
                  <UserAvatar>
                    <ImgAvatar src={User} alt="Chatbot"></ImgAvatar>
                  </UserAvatar>
                  <p>(99) 99999-9999</p>
                </PreviewHeader>
                <PreviewBody id="message-preview" className="message-preview">
                  {imagePreview && (
                    <ImagePreview
                      src={imagePreview}
                      alt="Imagem de pré-visualização"
                    />
                  )}
                  <Paragraph></Paragraph>
                </PreviewBody>
                <PreviewFooter>
                  <p>Escreva sua mensagem</p>
                  <img src={Send} alt="Send" />
                </PreviewFooter>
              </PreviewWrap>
            </PreviewContainer>
          </CardPhone>
        </Col>
      </Row>
    </div>
  );
};

export default Messages;
